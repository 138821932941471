/* Scroll bar */
::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #bac0c2;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #949a9b;
}

/* antd override */
.ant-collapse-content-box {
  padding-top: 10px !important;
  padding-bottom: 5px !important;
}

.ant-menu-item a {
  text-decoration: none;
}

.ant-transfer-list-header {
  display: block !important;
}
/* .list-group-item :first-child {
  margin-right: 0;
} */

.upload-list-inline .ant-upload-list-item {
  float: left;
  margin-inline-end: 8px;
}

.ant-upload-rtl.upload-list-inline .ant-upload-list-item {
  float: right;
  display: inline-flex;
  max-width: 150px;
}
